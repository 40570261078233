import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { Box, Divider, Typography } from '@mui/material';
import { Link, Button } from 'gatsby-material-ui-components';
import styled from '@emotion/styled';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Ponuka = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>Ponuka školení - {data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <Typography variant="h2" component="h1">
                    Ponuka školení
                </Typography>

                <Typography>Toto je zoznam školení, ktoré aktuálne ponúkam:</Typography>

                <CourseInfo>
                    <Typography variant="h3" component="h2">
                        <Link to="/react-kurz">Intenzívny React kurz</Link>
                    </Typography>

                    <Typography>
                        Tri a pol až štvordňový workshop, na ktorom sa naučíte pracovať v React-e,
                        manažovať stav aplikácie cez kontext alebo Redux, routovanie riešiť cez
                        React Router a ako bonus si ukážeme aj základy práce v MUI.
                    </Typography>

                    <CourseLink to="/react-kurz" variant="contained" endIcon={<ChevronRightIcon />}>
                        Viac informácií
                    </CourseLink>
                </CourseInfo>

                <Divider />

                <CourseInfo>
                    <Typography variant="h3" component="h2">
                        <Link to="/solid-principy-v-php">SOLID princípy pre PHP programátorov</Link>
                    </Typography>

                    <Typography>
                        SOLID princípy predstavujú základ dobrého softvérového návrhu,
                        udržiavateľného a rozširovateľného kódu a mal by ich teda poznať každý
                        programátor. Ich dodržiavanie pomôže udržať váš kód čistý a ľahko
                        upravovateľný.
                    </Typography>

                    <CourseLink
                        to="/solid-principy-v-php"
                        variant="contained"
                        endIcon={<ChevronRightIcon />}
                    >
                        Viac informácií
                    </CourseLink>
                </CourseInfo>

                <Divider />

                <CourseInfo>
                    <Typography variant="h3" component="h2">
                        <Link to="/zaklady-programovania-v-javascripte">
                            Základy programovania (v JavaScripte)
                        </Link>
                    </Typography>

                    <Typography>
                        Toto je 15 dielne školenie určené pre úplných začiatočníkov v programovaní.
                        Je vhodné pre ľudí, ktorí sa chcú naučiť programovať alebo potrebujú z
                        nejakého dôvodu poznať JavaScript k svojej práci (napríklad pre písanie
                        testovacích scenárov, skriptov pre GTM, prípadne len viac rozumieť práci
                        programátorov)
                    </Typography>

                    <CourseLink
                        to="/zaklady-programovania-v-javascripte"
                        variant="contained"
                        endIcon={<ChevronRightIcon />}
                    >
                        Viac informácií
                    </CourseLink>
                </CourseInfo>
            </Layout>
        </>
    );
};

const CourseInfo = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const CourseLink = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
})) as typeof Button;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default Ponuka;
